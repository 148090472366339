import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Content = styled.div`
  border-bottom: 1px solid var(--border-hairline);
`;

export const Faqs = styled(motion.div)`
  display: flex;
  gap: var(--spacing-lg);
  flex-direction: column;
  overflow: hidden;
  padding-bottom: var(--spacing-md);

  body[data-mobile='false'] & {
    padding: 0 var(--spacing-md) var(--spacing-md) var(--spacing-md);
  }
`;

export const Trigger = styled.div`
  padding: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;

  body[data-mobile='true'] & {
    padding: var(--spacing-md) 0;
  }

  :after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const Faq = styled.div`
  padding-top: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;
