import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

export function useConversionQuestionnaireStepsQuery() {
  const { conversionQuestionnaireService } = useServices();

  return useQuery({
    queryKey: ['conversion-questionnaire-steps'],
    queryFn: () => conversionQuestionnaireService.getAll(),
  });
}
