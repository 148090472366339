import { useState } from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText';

import { Content, Faq, Faqs, Trigger } from './QuestionnaireResultsFaq.styles';

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

interface QuestionnaireFaq {
  question: string;
  answer: string;
}

interface QuestionnaireResultsFaqProps {
  faqs: QuestionnaireFaq[];
}

export function QuestionnaireResultsFaq({ faqs }: QuestionnaireResultsFaqProps) {
  const [open, setOpen] = useState(false);

  return (
    <Content>
      <Trigger onClick={() => setOpen((open) => !open)}>
        <RMText type="sans" size="m" bold color="primary">
          Frequently asked questions
        </RMText>
        <FontAwesomeIcon size="lg" icon={open ? faMinus : faPlus} color="var(--primary)" />
      </Trigger>
      <AnimatePresence initial={false}>
        {open && (
          <Faqs
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {faqs.map(({ question, answer }) => (
              <Faq key={question}>
                <RMText type="sans" size="s" bold color="on-surface-primary">
                  {question}
                </RMText>
                <RMText type="sans" size="s" color="on-surface-primary">
                  {answer}
                </RMText>
              </Faq>
            ))}
          </Faqs>
        )}
      </AnimatePresence>
    </Content>
  );
}
