import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Content = styled.div`
  padding-top: var(--spacing-md);
  border-bottom: 1px solid var(--border-hairline);
`;

export const Reviews = styled(motion.div)`
  overflow: hidden;

  body[data-mobile='false'] & {
    padding: 0 var(--spacing-md);
  }
`;

export const Cards = styled.div`
  display: flex;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);

  body[data-mobile='true'] & {
    margin-top: var(--spacing-md);
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);

  border-radius: 1.5rem;
  border: 1px solid var(--border-hairline);
  background: var(--surface-bright);
  padding: var(--spacing-lg);
  box-shadow: var(--elevation-bottom-1);

  body[data-mobile='true'] & {
    margin-top: 0;
    width: 100%;
  }
`;

export const Trigger = styled.div`
  padding: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;

  body[data-mobile='true'] & {
    padding: var(--spacing-md) 0;
  }

  :after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const Trustpilot = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xl) 0;
  gap: 0.625rem;

  body[data-mobile='true'] & {
    padding: var(--spacing-lg) 0;
    justify-content: center;
  }
`;

export const Stars = styled.img`
  width: 5rem;
`;

export const TrustpilotButton = styled.button`
  height: 1.5rem;
  padding: 0 var(--spacing-xs);
  border-radius: var(--radius-minimal);
  border: 1px solid var(--border-weak);
  background: var(--surface-dim);

  display: flex;
  align-items: center;
  gap: var(--spacing-2xs);
`;

export const StarIcon = styled.img`
  width: 0.75rem;
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-top: auto;
`;
