import { QuestionnaireResultsFaq } from './QuestionnaireResultsFaq';
import { QuestionnaireResultsHeader } from './QuestionnaireResultsHeader';
import { QuestionnaireResultsQuestions } from './QuestionnaireResultsQuestions';
import { QuestionnaireResultsReviews } from './QuestionnaireResultsReviews';
import { QuestionnaireResultsRoot } from './QuestionnaireResultsRoot';

export const QuestionnaireResults = {
  Root: QuestionnaireResultsRoot,
  Header: QuestionnaireResultsHeader,
  Questions: QuestionnaireResultsQuestions,
  Reviews: QuestionnaireResultsReviews,
  Faq: QuestionnaireResultsFaq,
};
