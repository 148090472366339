import { useMemo } from 'react';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Blurred, Card, Content, Questions, QuestionsCount } from './QuestionnaireResultsQuestions.styles';

interface QuestionnaireQuestionCard {
  id: string;
  category: string;
  question: string;
}

interface QuestionnaireResultsQuestionsProps {
  questions: QuestionnaireQuestionCard[];
}

export function QuestionnaireResultsQuestions({ questions }: QuestionnaireResultsQuestionsProps) {
  const isMobile = useIsMobileViewport();

  const randomQuestions = useMemo(() => {
    if (questions.length == 0) {
      return [];
    }

    const indexes = new Set<number>();
    while (indexes.size < 3 && indexes.size < questions.length) {
      indexes.add(Math.floor(Math.random() * questions.length));
    }

    return Array.from(indexes).map((index) => questions[index]);
  }, [questions]);

  const blurredQuestion = useMemo(() => {
    if (questions.length == 0) {
      return null;
    }

    return questions[Math.floor(Math.random() * questions.length)];
  }, [questions]);

  const countMoreQuestions = questions.length + 50 - 3;

  return (
    <Content>
      <RMText type="serif" size="l" color="on-surface-primary">
        Your customized questions
      </RMText>
      <RMSpacer direction="column" spacing={isMobile ? 'md' : 'xl'} />
      <Questions>
        {randomQuestions.map(({ id, category, question }) => (
          <Card key={id}>
            <RMText type="sans" size="xs" bold color="primary-decorative">
              {category}
            </RMText>
            <RMText type="serif" size="m" color="on-surface-primary">
              {question}
            </RMText>
          </Card>
        ))}
        {countMoreQuestions > 0 && (
          <Card>
            <Blurred>
              <RMText type="sans" size="xs" bold color="primary-decorative">
                {blurredQuestion?.category}
              </RMText>
              <RMText type="serif" size="m" color="on-surface-primary">
                {blurredQuestion?.question}
              </RMText>
            </Blurred>
            <QuestionsCount type="sans" size="l" bold color="on-surface-primary">
              +{countMoreQuestions}
            </QuestionsCount>
          </Card>
        )}
      </Questions>
    </Content>
  );
}
