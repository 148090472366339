import { useCallback } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { NotFoundError } from '@remento/types/error';
import { create, StoreApi, useStore } from 'zustand';

import { QuestionnaireAnalyticsService } from '@/services/analytics/questionnaire-analytics';
import { ConversionQuestionnaireStep } from '@/services/cms/conversion-questionnaire';

import { getQuestionnairePath } from '../routing';

import {
  QuestionnaireActiveStepState,
  QuestionnaireState,
  QuestionnaireStateRepository,
} from './services/questionnaire-state';

export interface QuestionnaireManager {
  store: StoreApi<QuestionnaireState>;
  navigate: NavigateFunction;
  repository: QuestionnaireStateRepository;
  analytics: QuestionnaireAnalyticsService;
  steps: Array<ConversionQuestionnaireStep>;
}

export function createQuestionnaireManager(
  navigate: NavigateFunction,
  repository: QuestionnaireStateRepository,
  analytics: QuestionnaireAnalyticsService,
  steps: Array<ConversionQuestionnaireStep>,
): QuestionnaireManager {
  // Initialize the state
  let state = repository.get();

  // If a step name changed in the cms, we should invalidate the current state and
  // start fresh to avoid issues
  if (state != null) {
    const activeStepChanged = state.active.name != steps[state.active.position]?.name;
    const previousStepChanged = state.steps.some((s) => steps.some((cmsStep) => cmsStep.name === s.name) == false);

    if (activeStepChanged || previousStepChanged) {
      state = null;
    }
  }

  if (state == null) {
    const firstStep = steps[0];
    state = {
      active: {
        name: firstStep.name,
        position: firstStep.position,
        state: 'question',
        options: [],
      },
      steps: [],
    };
    repository.save(state);
  }

  // Initialize the manager
  const managerState = state;
  const manager: QuestionnaireManager = {
    store: create<QuestionnaireState>(() => managerState),
    navigate,
    repository,
    analytics,
    steps,
  };

  // Done
  return manager;
}

export function getActiveStepState(manager: QuestionnaireManager): QuestionnaireActiveStepState {
  return manager.store.getState().active;
}

export function useActiveStepState(manager: QuestionnaireManager): QuestionnaireActiveStepState {
  return useStore(
    manager.store,
    useCallback((store) => store.active, []),
  );
}

export function getQuestionnaireStep(manager: QuestionnaireManager, name: string): ConversionQuestionnaireStep {
  const step = manager.steps.find((s) => s.name === name);
  if (step == null) {
    throw new NotFoundError('questionnaire-step-not-found');
  }
  return step;
}

export function useQuestionnaireStep(manager: QuestionnaireManager, name: string): ConversionQuestionnaireStep {
  return getQuestionnaireStep(manager, name);
}

export function setSelectedOptions(manager: QuestionnaireManager, options: Array<string>): void {
  manager.store.setState(
    (state) => ({
      ...state,
      active: {
        ...state.active,
        options,
      },
    }),
    true,
  );
  manager.repository.save(manager.store.getState());
}

export function toggleOption(manager: QuestionnaireManager, option: string): void {
  const activeStepState = getActiveStepState(manager);
  const step = getQuestionnaireStep(manager, activeStepState.name);

  if (step.questionOptionsType === 'checkbox') {
    const options = new Set(activeStepState.options);
    if (options.has(option)) {
      manager.analytics.onQuestionnaireQuestionDeselected(activeStepState.name, option);
      options.delete(option);
    } else {
      manager.analytics.onQuestionnaireQuestionSelected(activeStepState.name, option);
      options.add(option);
    }
    setSelectedOptions(manager, Array.from(options));
    return;
  }

  if (activeStepState.options.length > 0 && activeStepState.options[0] === option) {
    manager.analytics.onQuestionnaireQuestionDeselected(activeStepState.name, option);
    setSelectedOptions(manager, []);
    return;
  }

  if (activeStepState.options.length > 0) {
    manager.analytics.onQuestionnaireQuestionDeselected(activeStepState.name, activeStepState.options[0]);
  }

  manager.analytics.onQuestionnaireQuestionSelected(activeStepState.name, option);
  setSelectedOptions(manager, [option]);
}

export function setStep(manager: QuestionnaireManager, name: string, stepState: 'question' | 'answer'): void {
  const state = manager.store.getState();
  if (state.active.name === name && state.active.state === stepState) {
    // Don't do anything if the step is already active
    return;
  }

  const step = manager.steps.find((s) => s.name === name);
  if (step == null) {
    // Or if the step is invalid
    return;
  }

  const newState: QuestionnaireState = {
    ...state,
    steps: state.steps.map((s) => ({ ...s })),
  };

  // Store the current step options
  if (state.active.state === 'question') {
    const stepIndex = state.steps.findIndex((s) => s.name === state.active.name);
    if (stepIndex !== -1) {
      newState.steps[stepIndex] = {
        name: state.active.name,
        options: state.active.options,
      };
    } else {
      newState.steps.push({
        name: state.active.name,
        options: state.active.options,
      });
    }
  }

  // Set the new active step
  const options = state.steps.find((s) => s.name === step.name)?.options ?? [];
  newState.active = {
    name: step.name,
    position: step.position,
    state: stepState,
    options: options,
  };

  // Update the state and the repository
  manager.repository.save(newState);
  manager.store.setState(newState, true);

  // Navigate to the new page
  manager.navigate(getQuestionnairePath(newState.active.name, newState.active.state));
}

export function nextStep(manager: QuestionnaireManager) {
  const state = manager.store.getState();

  if (state.active.state === 'question') {
    setStep(manager, state.active.name, 'answer');
    return;
  }

  // If it's not the last item, go to the next one
  const nextStep = manager.steps[state.active.position + 1];
  if (nextStep != null) {
    setStep(manager, nextStep.name, 'question');
  }
}

export function previousStep(manager: QuestionnaireManager) {
  const state = manager.store.getState();

  if (state.active.state === 'answer') {
    setStep(manager, state.active.name, 'question');
    return;
  }

  const position = state.active.position;
  const previousStep = position > 0 ? manager.steps[position - 1] : null;
  if (previousStep != null) {
    setStep(manager, previousStep.name, 'answer');
  }
}

export function useAnswers(manager: QuestionnaireManager, stepName: string) {
  return useStore(
    manager.store,
    useCallback(
      (store) => {
        const selectedStep = store.steps.find((step) => step.name === stepName);

        return selectedStep?.options;
      },
      [stepName],
    ),
  );
}
