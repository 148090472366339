import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BookSrc from '@/assets/remento-book.webp';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import {
  Book,
  BookContent,
  Bullets,
  Content,
  Header,
  Left,
  Price,
  Right,
  Score,
} from './QuestionnaireResultsHeader.styles';

interface QuestionnaireResultsHeaderProps {
  questionsCount: number;
  title: string;
  subtitle: string;
  onSignUp: () => void;
}

export function QuestionnaireResultsHeader({
  questionsCount,
  title,
  subtitle,
  onSignUp,
}: QuestionnaireResultsHeaderProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Content>
      <Left>
        <Header>
          <Score type="sans" size="xl" bold color="on-surface-primary">
            {questionsCount}
          </Score>
          <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
            {title}
          </RMText>
        </Header>
        <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
        <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" lineHeight="m">
          {subtitle}
        </RMText>
        <Bullets>
          <RMSpacer spacing="lg" direction="column" />
          <RMStack spacing="xs" direction="row" center>
            <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
            <RMText type="sans" size={isMobile ? 'xs' : 's'} bold color="on-surface-primary">
              1 year subscription for creating stories
            </RMText>
          </RMStack>
          <RMSpacer spacing="xs" direction="column" />
          <RMStack spacing="xs" direction="row" center>
            <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
            <RMText type="sans" size={isMobile ? 'xs' : 's'} bold color="on-surface-primary">
              1 hardcover, color-printed book
            </RMText>
          </RMStack>
          <RMSpacer spacing="xs" direction="column" />
          <RMStack spacing="xs" direction="row" center>
            <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
            <RMText type="sans" size={isMobile ? 'xs' : 's'} bold color="on-surface-primary">
              Unlimited recordings
            </RMText>
          </RMStack>
          <RMSpacer spacing="xs" direction="column" />
          <RMStack spacing="xs" direction="row" center>
            <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
            <RMText type="sans" size={isMobile ? 'xs' : 's'} bold color="on-surface-primary">
              Free shipping in the US
            </RMText>
          </RMStack>
        </Bullets>
      </Left>
      <Right>
        <BookContent>
          {!isMobile && <Book src={BookSrc} alt="book" />}
          <RMText type="sans" size="s" bold color="on-surface-primary">
            The Remento Book
          </RMText>
          <RMSpacer spacing="xs" direction="column" />
          <Price>
            <RMText
              type="serif"
              size="xs"
              color="on-surface-tertiary"
              style={{ textDecoration: 'line-through', alignSelf: 'self-end' }}
            >
              $168
            </RMText>
            <RMText
              type="serif"
              size="xs"
              color="on-surface-tertiary"
              style={{ textDecoration: 'line-through', alignSelf: 'self-end' }}
            >
              $99
            </RMText>
            <RMText type="sans" size="xs" bold color="on-surface-secondary">
              $79
            </RMText>
          </Price>
          <RMSpacer spacing="md" direction="column" />
          <RMButton color="white" fullWidth background="primary" size="medium" onClick={onSignUp}>
            Buy Now & Save
          </RMButton>
          <RMSpacer spacing="sm" direction="column" />
          <RMText type="sans" size="xs" color="on-surface-secondary">
            Use code <b>SAVE20</b> at checkout
          </RMText>
        </BookContent>
      </Right>
    </Content>
  );
}
