import { PropsWithChildren, useEffect, useState } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ButtonContent, Content, ContentWrapper } from './QuestionnaireResultsRoot.styles';

const variants = {
  show: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: '100%' },
};

type QuestionnaireResultsRootProps = PropsWithChildren<{
  onSignUp: () => void;
}>;

export function QuestionnaireResultsRoot({ onSignUp, children }: QuestionnaireResultsRootProps) {
  const isMobile = useIsMobileViewport();

  const [scrollYPosition, setScrollYPosition] = useState(0);
  const screenHeight = window.innerHeight;

  const handleScroll = () => {
    setScrollYPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Content>
      <ContentWrapper>
        {children}
        <RMSpacer direction="column" spacing="5xl" />
        {isMobile && scrollYPosition > screenHeight && (
          <ButtonContent
            initial="hidden"
            animate="show"
            exit="hidden"
            variants={variants}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <RMButton color="white" fullWidth background="primary" size="medium" onClick={onSignUp}>
              Sign up
            </RMButton>
          </ButtonContent>
        )}
      </ContentWrapper>
    </Content>
  );
}
