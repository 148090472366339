import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
  padding-top: 3rem;

  body[data-mobile='true'] & {
    padding-left: 0;
    padding-right: 0;
    padding-top: var(--spacing-xl);
  }
`;

export const ContentWrapper = styled.div`
  max-width: 73rem;

  body[data-mobile='true'] & {
    width: 100%;
    padding: 0 var(--spacing-md);
  }
`;

export const ButtonContent = styled(motion.div)`
  border-top: 1px solid var(--border-hairline);
  background-color: var(--surface);
  padding: var(--spacing-md);
  padding-top: var(--spacing-xl);
  position: sticky;
  bottom: 0;
`;
