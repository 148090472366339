import { LocalStoreRepository } from '@/services/local/local-store';

import { QuestionnaireState, QuestionnaireStateRepository } from './questionnaire-state.types';

export class LocalQuestionnaireStateRepository implements QuestionnaireStateRepository {
  constructor(private localStore: LocalStoreRepository) {}

  save(state: QuestionnaireState): void {
    this.localStore.setItem('item', state);
  }

  get(): QuestionnaireState | null {
    return this.localStore.getItem('item');
  }
}
