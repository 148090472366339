import { RMLoader } from '@/components/RMLoader/RMLoader';
import { RMText } from '@/components/RMText/RMText';

import { Content } from './QuestionnaireLoading.styles';

interface QuestionnaireLoading {
  lastStep?: boolean;
}

export function QuestionnaireLoading({ lastStep = false }) {
  return (
    <Content>
      <RMLoader />
      {lastStep && (
        <RMText type="sans" size="s" bold color="on-surface-primary">
          Customizing your book...
        </RMText>
      )}
    </Content>
  );
}
