import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  padding-top: var(--spacing-xl);
  padding-bottom: 3rem;

  body[data-mobile='true'] & {
    padding-bottom: var(--spacing-2xl);
  }
`;

export const Questions = styled.div`
  display: flex;
  gap: var(--spacing-lg);
  flex-wrap: wrap;

  body[data-mobile='true'] & {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
`;

export const Card = styled.div`
  // ignoring gap sum
  width: calc(25% - 0.95rem);

  // to fit 3 in the first row for minimal desktop resolution
  min-width: 14.185rem;

  box-sizing: border-box;
  height: 11.25rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  border-radius: 1.5rem;
  border: 1px solid var(--border-hairline);
  background: var(--surface-bright);
  padding: var(--spacing-md);
  box-shadow: var(--elevation-bottom-1);
  position: relative;

  body[data-mobile='true'] & {
    width: 100%;
    height: 8.75rem;
  }
`;

export const Blurred = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  filter: blur(8px);
`;

export const QuestionsCount = styled(RMText)`
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--primary-decorative);
  border-radius: var(--radius-full);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
