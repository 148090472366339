import { useState } from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';

import ratingStarsSrc from '@/assets/rating-start-5.svg';
import starSrc from '@/assets/trustpilot-star.svg';
import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMText } from '@/components/RMText/RMText';

import {
  Author,
  Card,
  Cards,
  Content,
  Reviews,
  StarIcon,
  Stars,
  Trigger,
  Trustpilot,
  TrustpilotButton,
} from './QuestionnaireResultsReviews.styles';

interface QuestionnaireReviews {
  position: number;
  title: string;
  message: string;
  author: string;
  authorAvatar: {
    url: string;
    mimeType: string;
  };
}

interface QuestionnaireResultsQuestionsProps {
  reviews: QuestionnaireReviews[];
  onTrustpilotClick: () => void;
}

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

export function QuestionnaireResultsReviews({ reviews, onTrustpilotClick }: QuestionnaireResultsQuestionsProps) {
  const [open, setOpen] = useState(false);

  return (
    <Content>
      <Trigger onClick={() => setOpen((open) => !open)}>
        <RMText type="sans" size="m" bold color="primary">
          What customers are saying
        </RMText>
        <FontAwesomeIcon size="lg" icon={open ? faMinus : faPlus} color="var(--primary)" />
      </Trigger>
      <AnimatePresence initial={false}>
        {open && (
          <Reviews
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <Cards>
              {reviews.map(({ title, message, author, authorAvatar }) => (
                <Card key={title}>
                  <Stars src={ratingStarsSrc} />
                  <RMText type="sans" size="s" bold color="on-surface-primary">
                    {title}
                  </RMText>
                  <RMText type="sans" size="xs" color="on-surface-primary">
                    {message}
                  </RMText>
                  <Author>
                    <RMAvatar src={authorAvatar.url} placeholder="" />
                    <RMText type="sans" size="xs" color="on-surface-primary">
                      {author}
                    </RMText>
                  </Author>
                </Card>
              ))}
            </Cards>
            <Trustpilot>
              <RMText type="sans" size="xs" color="inverse-on-primary">
                See more reviews on
              </RMText>
              <TrustpilotButton onClick={onTrustpilotClick}>
                <StarIcon src={starSrc} />
                <RMText type="sans" size="xxs">
                  Trustpilot
                </RMText>
              </TrustpilotButton>
            </Trustpilot>
          </Reviews>
        )}
      </AnimatePresence>
    </Content>
  );
}
