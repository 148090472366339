import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-xl);
  border-bottom: 1px solid var(--border-hairline);

  body[data-mobile='true'] & {
    flex-direction: column;
    border-bottom: none;
    padding-bottom: var(--spacing-xs);
  }
`;
export const Left = styled.div`
  max-width: 34.75rem;
  margin-right: var(--spacing-xl);

  body[data-mobile='true'] & {
    width: 100%;
    max-width: unset;
    margin-right: 0;
  }
`;
export const Right = styled.div``;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-2xl);

  body[data-mobile='true'] & {
    flex-direction: column;
    gap: var(--spacing-md);
  }
`;
export const Score = styled(RMText)`
  min-height: 5rem;
  min-width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--primary-decorative);
  border-radius: var(--radius-full);
`;

export const Bullets = styled.div``;

export const BookContent = styled.div`
  background-color: var(--surface-dim);
  border-radius: var(--radius-round);
  width: 23.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: var(--spacing-xl);

  body[data-mobile='true'] & {
    margin-top: var(--spacing-md);
    width: 100%;
  }
`;

export const Book = styled.img`
  width: 10rem;
`;

export const Price = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
`;
