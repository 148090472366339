import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useServices } from '@/Services';
import { LocalStoreRepositoryWrapper } from '@/services/local/local-store/local-store.repository.wrapper';

import { LocalQuestionnaireStateRepository, QuestionnaireStateRepository } from './questionnaire-state';

interface QuestionnaireServices {
  questionnaireStateRepository: QuestionnaireStateRepository;
}

const QuestionnaireServicesContext = createContext<QuestionnaireServices | null>(null);

export type QuestionnaireServicesProviderProps = PropsWithChildren<unknown>;

export function QuestionnaireServicesProvider({ children }: QuestionnaireServicesProviderProps) {
  const { localStorageRepository } = useServices();

  const services = useMemo<QuestionnaireServices>(
    () => ({
      questionnaireStateRepository: new LocalQuestionnaireStateRepository(
        new LocalStoreRepositoryWrapper('@remento/questionnaire-state', localStorageRepository),
      ),
    }),
    [localStorageRepository],
  );

  return <QuestionnaireServicesContext.Provider value={services}>{children}</QuestionnaireServicesContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useQuestionnaireServices(): QuestionnaireServices {
  const context = useContext(QuestionnaireServicesContext);
  if (!context) {
    throw new Error('useQuestionnaireServices must be used inside a QuestionnaireServicesProvider');
  }
  return context;
}
