import { styled } from '@linaria/react';

export const PickerItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
  position: relative;
  overflow: hidden;

  :first-child {
    border-top-left-radius: var(--radius-round);
    border-top-right-radius: var(--radius-round);
  }

  :last-child {
    border-bottom-left-radius: var(--radius-round);
    border-bottom-right-radius: var(--radius-round);
  }

  :after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const PickerItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  min-height: 3.25rem;
  width: 100%;
  padding: var(--spacing-md);
  background-color: var(--surface-dim);

  &[data-selected='true'] {
    background-color: #968e691f;
  }

  &[data-disabled='true'] {
    opacity: 0.6;
  }

  &:not([data-disabled='true']) {
    cursor: pointer;
  }

  &[data-error='true'] {
    border: 1px solid var(--danger);
  }
`;
