import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMText } from '@/components/RMText/RMText';
import { getCheckoutAudiencePath, getSigninPath } from '@/modules/routing';
import { usePurchaserOnboardingPromptTemplatesByTagsQuery } from '@/services/cms/prompt-template/prompt-template.service.hook';

import { QuestionnaireLoading } from '../components/QuestionnaireLoading/QuestionnaireLoading';
import { QuestionnaireResults } from '../components/QuestionnaireResults';
import { QuestionnaireManager, useActiveStepState, useAnswers, useQuestionnaireStep } from '../questionnaire.manager';

interface QuestionnaireResultsContainerProps {
  manager: QuestionnaireManager;
}

export function QuestionnaireResultsContainer({ manager }: QuestionnaireResultsContainerProps) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const activeStepState = useActiveStepState(manager);
  const step = useQuestionnaireStep(manager, activeStepState.name);
  const answers = useAnswers(manager, activeStepState.name);
  const tags = answers?.flatMap((answer) => {
    const option = step.questionOptions.find((option) => option.name === answer);
    if (option == null || option.tags == null) {
      return [answer];
    }
    return option.tags;
  });

  const { data } = usePurchaserOnboardingPromptTemplatesByTagsQuery(tags);
  const questions = data?.map((promptTemplate) => ({
    id: promptTemplate.questions[0].id,
    category: promptTemplate.tags[0].title,
    question: promptTemplate.questions[0].text,
  }));
  const title = step?.answer.type === 'results' ? step.answer.title : '';
  const subtitle = step?.answer.type === 'results' ? step.answer.subtitle : '';
  const reviews = step?.answer.type === 'results' ? step.answer.reviews : [];
  const faqs = step?.answer.type === 'results' ? step.answer.faqs : [];

  const questionCount = questions ? questions.length + 50 : 50;

  const handleSignUp = useCallback(() => {
    navigate(getCheckoutAudiencePath());
  }, [navigate]);

  const handleTrustpilotClick = useCallback(() => {
    open('https://www.trustpilot.com/review/remento.co', '_blank');
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading || data == null) {
    return <QuestionnaireLoading lastStep={true} />;
  }

  return (
    <QuestionnaireResults.Root onSignUp={handleSignUp}>
      <QuestionnaireResults.Header
        questionsCount={questionCount}
        title={title}
        subtitle={subtitle}
        onSignUp={handleSignUp}
      />
      <QuestionnaireResults.Questions questions={questions || []} />
      <RMText type="serif" size="l" color="on-surface-primary">
        Learn more
      </RMText>
      <QuestionnaireResults.Reviews reviews={reviews} onTrustpilotClick={handleTrustpilotClick} />
      <QuestionnaireResults.Faq faqs={faqs} />
    </QuestionnaireResults.Root>
  );
}
