import { RMCheckbox } from '../../../../components/RMCheckbox/RMCheckbox';
import { RMText } from '../../../../components/RMText/RMText';

import { PickerItem, PickerItemWrapper } from './QuestionnairePickerItem.styles';

export interface RMPickerItemProps {
  label: string;
  value: string;
  selected: boolean;
  disabled?: boolean;
  square?: boolean;
  error?: string | null;
  onClick: (value: string) => void;
}

export function QuestionnairePickerItem({
  label,
  value,
  selected,
  disabled,
  square,
  error,
  onClick,
}: RMPickerItemProps) {
  return (
    <PickerItemWrapper>
      <PickerItem
        data-selected={selected}
        data-disabled={disabled}
        data-error={error != null}
        onClick={() => {
          if (!disabled) {
            onClick(value);
          }
        }}
      >
        <RMCheckbox checked={selected} disabled={disabled} square={square} size="sm" />
        <RMText type="sans" size="s" color="on-surface-primary">
          {label}
        </RMText>
      </PickerItem>
      {error && (
        <RMText type="sans" size="xs" color="danger">
          {error}
        </RMText>
      )}
    </PickerItemWrapper>
  );
}
